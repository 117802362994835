export const colorOptions = {
  '#4fb9af': 'Turquoise Teal',
  '#EA8B6A': 'Apricot',
  '#9D2515': 'Pure Red',
  '#FFFFFF': 'White',
  Brass: 'Brass (Brushed)',
  '#000000': 'Black',
  '#EADBBC': 'Coffee Cream',
  '#A69A8C': 'Warm Gray',
  '#D3A35B': 'Golden',
  '#FDBB26': 'Sunglow Yellow',
  '#FD8546': 'Eyelid Orange',
  '#CE6B4E': 'Copper Suede',
  '#C0979F': 'Champagne Pink',
  '#3E8E51': 'Forest Green',
  '#B5D969': 'Lime Lizard Green',
  '#81CC91': 'Mint Green',
  '#91C3DE': 'Sky Blue',
  '#F0A4AB': 'Melon Pink',
  '#746A9C': 'Orchid Purple',
  '#3E037E': 'Strange Purple',
  '#204C89': 'Azure Blue',
  '#C9998B': 'Rose Gold',
  '#120F31': 'Navy Blue',
  '#52253A': 'Black Cherry',
  '#A50456': 'Sangria',
  '#536000': 'Olive',
  '#333B00': 'Earthy Green',
  '#C3B9B0': 'Metallic'

  // Add more mappings as needed
}

export const screenColorOptions = {
  '#4fb9af': 'Turquoise Teal',
  '#EA8B6A': 'Apricot',
  '#9D2515': 'Pure Red',
  '#FFFFFF': 'White',
  'Stainless Steel': 'Stainless (Brushed)',
  Brass: 'Brass (Brushed)',
  '#000000': 'Black',
  '#EADBBC': 'Coffee Cream',
  '#A69A8C': 'Warm Gray',
  '#D3A35B': 'Golden',
  '#FDBB26': 'Sunglow Yellow',
  '#FD8546': 'Eyelid Orange',
  '#CE6B4E': 'Copper Suede',
  '#C0979F': 'Champagne Pink',
  '#3E8E51': 'Forest Green',
  '#B5D969': 'Lime Lizard Green',
  '#81CC91': 'Mint Green',
  '#91C3DE': 'Sky Blue',
  '#F0A4AB': 'Melon Pink',
  '#746A9C': 'Orchid Purple',
  '#3E037E': 'Strange Purple',
  '#204C89': 'Azure Blue',
  '#C9998B': 'Rose Gold',
  '#120F31': 'Navy Blue',
  '#52253A': 'Black Cherry',
  '#A50456': 'Sangria',
  '#536000': 'Olive',
  '#333B00': 'Earthy Green',
  '#C3B9B0': 'Metallic'

  // Add more mappings as needed
}

export const fabricColorOptions = {
  '#FFFFFF': 'White',
  '#000000': 'Black'
  // Add more mappings as needed
}

export const options = {
  screenType: {
    Motif: 'MOT',
    Scales: 'SCA',
    Stripes: 'STR',
    Windows: 'WIN',
    Holes: 'HOL'
  },
  micType: {
    Ribbon: 'R',
    Condenser: 'C'
  },
  logoType: {
    Logo: 'L',
    'Add Pattern Screen': 'P',
    'Custom Logo': 'C'
  },
  colors: {
    White: 0,
    Black: 1,
    'Stainless Steel': 2,
    Brass: 3,
    'Turquoise Teal': 4,
    Apricot: 5,
    'Azure Blue': 6,
    'Mint Green': 7,
    'Champagne Pink': 8,
    'Coffee Cream': 9,
    'Copper Suede': 10,
    'Eyelid Orange': 11,
    'Forest Green': 12,
    Golden: 13,
    'Lizard Lime Green': 14,
    'Melon Pink': 15,
    'Orchid Purple': 16,
    'Peach Orange': 17,
    'Pure Red': 18,
    'Rose Gold': 19,
    'Sky Blue': 20,
    'Sunglow Yellow': 21,
    'Warm Gray': 22,
    'Strange Purple': 23,
    'Trans Flag': 24,
    'Pan Flag': 25,
    'Pride Flag': 26,
    Sad13: 27,
    'Flecks and Blems': 28,
    'Lost Pink': 29,
    Alvin: 30,
    'Devilish Red': 31,
    'Black Cherry': 32,
    'Raw Sandblast': 33,
    'Earthy Green': 34,
    Sand: 35,
    'Ultra Matte Black': 36,
    'Polished Copper': 37,
    'Ombre Orange': 39,
    'Walnut Wood': 40,
    'Navy Blue': 41,
    'Sparkly Black': 42,
    'GameCube Purple': 43,
    'Ombre Forest Green': 44,
    'VK Black': 45,
    'VK Red': 46,
    'Ombre Black/White': 47,
    'Patina Brass': 48,
    'Burly Dark Green': 49,
    'Gold Plated': 50,
    'Iridescent Sparkle': 51,
    Champagne: 52,
    'Daru Jones Ombre': 53
  }
}
