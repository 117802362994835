import { createRoot } from 'react-dom/client'
import './styles.css'
import App from './App'

// Add Firefox detection and class assignment before rendering
if (/FxiOS/i.test(navigator.userAgent)) {
  document.documentElement.classList.add('firefox-ios')
}

createRoot(document.getElementById('root')).render(<App />)
